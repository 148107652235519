import {
  EventDetailsImageRatio,
  EventDetailsButtonStyle,
  HEADER_LAYOUT,
  IMAGE_SCALING,
} from '@wix/wix-events-commons-statics'
import {PageComponentSettings} from '../../stylesParams'
import {State} from '../types'
import {hasScheduleItems} from './schedule'

export const isShortDateLocationVisible = (settings: PageComponentSettings) => settings.shortDateLocationVisible

export const isDescriptionEnabled = (settings: PageComponentSettings) => settings.descriptionVisible

export const getImagePosition = (settings: PageComponentSettings) => settings.imagePosition

export const getImageRatio = (settings: PageComponentSettings) =>
  settings.headerLayout === HEADER_LAYOUT.SIDE_BY_SIDE ? EventDetailsImageRatio['1:1'] : settings.imageRatio

export const getImageOpacity = (settings: PageComponentSettings) => settings.imageOpacity

export const isFitImage = (settings: PageComponentSettings) => settings.imageScaling === IMAGE_SCALING.FIT

export const getButtonStyle = (settings: PageComponentSettings) => settings.buttonStyle

export const isSquareImage = (settings: PageComponentSettings) =>
  settings.imageRatio === EventDetailsImageRatio['1:1'] || isSideBySideHeaderLayout(settings)

export const getContentAlignment = (settings: PageComponentSettings) => settings.contentAlignment

export const getHeaderAlignment = (settings: PageComponentSettings) => settings.headerAlignment

export const getFormButtonStyle = (settings: PageComponentSettings) => settings.formButtonStyle

export const isSocialShareVisible = (settings: PageComponentSettings) => settings.socialShareVisible

export const isScheduleVisible = (state: State) => state.component.settings.scheduleVisible && hasScheduleItems(state)

export const isGroupVisibilityEnabled = (settings: PageComponentSettings) => settings.groupVisible

export const isHeaderRegistrationButtonVisibilityEnabled = (settings: PageComponentSettings) =>
  settings.headerRegistrationButtonVisible ?? settings.rsvpButtonVisible

export const isPageRegistrationButtonVisibilityEnabled = (settings: PageComponentSettings) =>
  settings.pageRegistrationButtonVisible ?? settings.rsvpButtonVisible

export const isMembersAvatarsVisibilityEnabled = (settings: PageComponentSettings) => settings.membersVisible

export const isAboutSectionVisibilityEnabled = (settings: PageComponentSettings) => settings.aboutSectionVisible

export const isMapEnabled = (settings: PageComponentSettings) => settings.mapVisible

export const isMembershipOffersVisibilityEnabled = (settings: PageComponentSettings) => settings.membershipsVisible

export const isRoundedButton = (buttonStyle: EventDetailsButtonStyle) =>
  [EventDetailsButtonStyle.FULL_ROUNDED, EventDetailsButtonStyle.HOLLOW_ROUNDED].includes(buttonStyle)

export const isHollowButton = (buttonStyle: EventDetailsButtonStyle) =>
  [EventDetailsButtonStyle.HOLLOW, EventDetailsButtonStyle.HOLLOW_ROUNDED].includes(buttonStyle)

export const isFullButton = (buttonStyle: EventDetailsButtonStyle) =>
  [EventDetailsButtonStyle.FULL, EventDetailsButtonStyle.FULL_ROUNDED].includes(buttonStyle)

export const getHeaderLayout = (settings: PageComponentSettings) => settings.headerLayout

export const isSideBySideHeaderLayout = (settings: PageComponentSettings) =>
  settings.headerLayout === HEADER_LAYOUT.SIDE_BY_SIDE

export const getHeaderImageAlignment = (settings: PageComponentSettings) => settings.headerImageAlignment

export const getHeaderBorderWidth = (settings: PageComponentSettings) => settings.headerBorderWidth
